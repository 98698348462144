import React from 'react';

const Card = ({ project }) => {

   return ( 
      // individual project card
      <React.Fragment>
         <div className="rounded-md shadow-lg flex flex-col bg-indigo-200 bg-opacity-50 border-2 border-gray-700 ">
            {/* card image */}
               <a  href={project.url} target="_blank">
                  <img className="rounded-t object-cover" src={project.image} alt={project.title} />
               </a>
            {/* card info */}
            <div className="text-center rounded-b py-4 flex flex-col flex-grow justify-between">
               <p className="text-2xl mb-2 px-4"> {project.title} </p>
               {/* <p className="mb-5 px-2"> Built with: {project.technology.join(', ')}</p> */}
               {/* <a href={project.url} target="_blank"> 
                  <span className="text-md font-medium border-2 border-transparent hover:border-black hover:bg-blue-500 hover:bg-opacity-70 hover:text-white rounded p-2">
                  Click here to see the project 
                  </span>
               </a> */}
            </div>
         </div>
      </React.Fragment>
   );
}
 
export default Card;