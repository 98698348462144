import React, { useRef, useState } from 'react'
import emailjs from 'emailjs-com'
import projectData from './projectdata.js';
import Card from './Card';

const Projects = () => {

   const form = useRef();
   const contactForm = document.getElementById('contact-form');
   const [emailSent, setEmailSent] = useState(false);
   const data = projectData;

   const sendEmail = (e) => {
      setEmailSent(false);
      e.preventDefault();

      emailjs.sendForm('service_qlifkd5', 'contact_form', form.current, 'user_Af4y8G8URiHEDFNaPf18Y')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

      e.target.reset();
      setEmailSent(true);
   };

   return (
      
      <div className="snap-container flex-1" >
         {/* About */}
         <section className="one p-3 md:p-12 lg:p-16 xl:p-20 lg:flex justify-center items-center" id="about">
            <h1 className="text-left text-xl lg:text-2xl lg:w-2/3 px-4 py-2 md:px-12 mt-4">Hey I'm Richard, and I'm a developer that enjoys creating various webpages with a specific focus on Javascript and React. Below are some projects built mostly with React, Tailwind, Express, PostgresQL, and MongoDB.  </h1>
         </section>

         {/* Projects */}
         <section className="two py-4 px-8 lg:px-4 xl:px-16" id="projects">
            <h1 className="text-4xl mb-2">Projects</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
               {data.map(project => (
                  <Card project={project}/>
               ))}
            </div>
         </section>

         {/* contact form */}
         <section className="three bg-gray-100 py-10 flex justify-center items-center" id="contact">
            <div className="m-auto w-3/4 md:w-2/3 lg:w-1/2 ">
               {/* ============= Contact Form ============== */}
               { emailSent && <div className="fade-in text-2xl border-4 border-blue-400 rounded-lg bg-gray-100 px-10 py-4 mb-2"> Thanks for your email. I'll get back to you as soon as I can </div> }
               <h2 className="text-2xl text-left mb-2">If you'd like to contact me, send me an email below!</h2>
               <form id="contact-form" ref={form} onSubmit={sendEmail} className="max-w-screen-md  border-black text-lg">
                  <input className="w-full p-2 mb-1 border-2 border-gray-600 shadow rounded-xl bg-gray-50 focus:bg-white focus:outline-none focus:ring-1 focus:border-blue-300" type="text" placeholder="Name" name="name" required />
                  <input className="w-full p-2 mb-1 border-2 border-gray-600 shadow rounded-xl bg-gray-50 focus:bg-white focus:outline-none focus:ring-1 focus:border-blue-300" type="email" placeholder="E-mail" name="email" required/>
                  <input className="w-full p-2 mb-1 border-2 border-gray-600 shadow rounded-xl bg-gray-50 focus:bg-white focus:outline-none focus:ring-1 focus:border-blue-300" type="text" placeholder="Subject" name="subject" required />
                  <textarea className="w-full p-2 mb-1 border-2 border-gray-600 shadow rounded-xl bg-gray-50 focus:bg-white focus:outline-none focus:ring-1 focus:border-blue-300" rows="10" type="text" placeholder="Message" name="message" required/>
                  <button className="text-white text-lg rounded-lg bg-blue-500 py-2 px-8 block">Submit</button>
               </form>
            </div>
         </section>
      </div>
   )
}

export default Projects
