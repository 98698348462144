import React from 'react'
import { AiFillGithub } from 'react-icons/ai';
import { FaLinkedin } from 'react-icons/fa';
import { IoIosPaper } from 'react-icons/io';

const Navbar = () => {
   return (
    
         <nav className="lg:w-1/5 bg-gradient-to-b from-blue-200 to-blue-100 border-b-4 rounded-b border-blue-200 lg:border-none flex flex-col justify-evenly items-center lg:justify-center lg:space-y-10 py-2 sticky top-0 px-2 ">
            <div className="flex items-center justify-between flex-col lg:space-y-1">
               <h1 className="text-2xl lg:text-5xl px-2"><a href="#about">Richard Chyan</a></h1>
               <h2 className="text-md lg:text-xl tracking-wide uppercase">Web Developer </h2>
            </div>
            <ul className="flex space-x-6 lg:flex-col lg:space-x-0 ">
               <li className="text-base lg:text-lg"><a href="#about" data-section="about" className="hover:border-black border-b-2 border-transparent">About</a></li>
               <li className="text-base lg:text-lg"><a href="#projects" data-section="projects" className="hover:border-black border-b-2 border-transparent">Projects</a></li>
               <li className="text-base lg:text-lg"><a href="#contact" data-section="contact" className="hover:border-black border-b-2 border-transparent">Contact</a></li>
            </ul>
            {/* profile links */}
            <div className="flex space-x-4 mt-2 text-xl lg:flex-col lg:space-x-0 lg:space-y-2 lg:text-3xl">
               <a href="https://github.com/richardchyan" target="_blank">
                  <AiFillGithub className="hover:text-blue-600" />
               </a>
               <a href="https://www.linkedin.com/in/richardchyan/" target="_blank">
                  <FaLinkedin className="hover:text-blue-600"/>
               </a>              
            </div>
         </nav>
     
         
      
   )
}

export default Navbar
