import pokedex from './assets/pokedex-image.png';
import crud from './assets/express-crud.png';
import markhameye from './assets/eye-site.png';
import shopapp from './assets/shop-app.jpg';
import jwtlogin from './assets/jwt-tasks.png';
import cssmocksite from './assets/css-mocksite.jpg';
import beatlescards from './assets/beatles-cards.jpg';
import cocktails from './assets/cocktails-search.png';
import moneymanager from './assets/money-manager.png';
import databasesearch from './assets/database-search.png';
import recipeMaker from './assets/recipe-maker.jpg';
import travelhub from  './assets/travelhub.png';
import graph from './assets/graph.jpg'

const projects = [

   // { 
   //    id: 8,
   //    title: 'Travel Hub Vacations',
   //    technology: ['React', 'Tailwind'],
   //    url: 'https://travelhubvacations.netlify.app',
   //    image: travelhub,
   // },
   { 
      id: 1,
      title: 'Recipe Maker with Dark Mode',
      technology: ['React', 'Auth0', 'PostgresQL', 'Express', 'Tailwind'],
      url: 'https://postgres-recipe-maker.netlify.app',
      image: recipeMaker,
   },
   { 
      id: 2,
      title: 'GPT3 AI Design Layout',
      technology: ['React', 'Tailwind'],
      url: 'https://css-mocksite.netlify.app',
      image: cssmocksite,
   },
   { 
      id: 9,
      title: 'Weather Chart',
      technology: ['React', 'Tailwind'],
      url: 'https://benevolent-daifuku-0b2d63.netlify.app/',
      image: graph,
   },
   {
      id: 3,
      title: 'Shopping App with Stripe Checkout',
      technology: ['Nodejs', 'React', 'Stripe', 'Tailwind'],
      url: 'https://pawnshop-app.netlify.app',
      image: shopapp,
   },
   {
      id: 4,
      title: 'Searchable Name Database',
      technology: ['React', 'PostgresQL', 'Express', 'Tailwind'],
      url: 'https://postgres-textsearch.herokuapp.com',
      image: databasesearch,
   },
   { 
      id: 5,
      title: 'Task Manager with JWT Authentication and Authorization',
      technology: ['Nodejs', 'React', 'JWT', 'Tailwind'],
      url: 'https://jwt-login.netlify.app',
      image: jwtlogin,
   },
   { 
      id: 6,
      title: 'Money Transaction Tracker',
      technology: ['React', 'Firebase/Firestore', 'Tailwind'],
      url: 'https://money-transaction-tracker.netlify.app',
      image: moneymanager
   },
   { 
      id: 7,
      title: 'Memory Matching Card Game',
      technology: ['React'],
      url: 'https://beatles-memory-game.netlify.app',
      image: beatlescards,
   },
 
   // { 
   //    id: 5,
   //    title: 'Mock Optometry Clinic Page',
   //    technology: ['React', 'Tailwind'],
   //    url: 'https://markhameye.netlify.app',
   //    image: markhameye
   // },
   // { 
   //    id: 4,
   //    title: 'Searchable Pokedex App',
   //    technology: ['React', 'Material UI'],
   //    url: 'https://react-pokemon-search.netlify.app/',
   //    image: pokedex,
   // },
   { 
      id: 8,
      title: 'Cocktails Recipe Database',
      technology: ['React', 'Material UI'],
      url: 'https://cocktail-recipes-database.netlify.app/',
      image: cocktails,
   },
   // { 
   //    id: 5,
   //    title: 'Express and MongoDB CRUD Application',
   //    technology: ['Material UI', 'Nodejs', 'React-Redux', 'MongoDB'],
   //    url: 'https://express-crud-posts.netlify.app/',
   //    image: crud,
   // },
  
   

]

export default projects;