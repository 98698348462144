import './App.css';
import Contact from './Contact';
import Navbar from './Navbar';
import Projects from './Projects';

function App() {
  return (
    <div className="App flex flex-col lg:flex-row">
      <Navbar />
      <Projects />
    </div>
  );
}

export default App;
